import { 
  USER_LOGIN_REQUEST, 
  USER_LOGIN_SUCCESS, 
  USER_LOGIN_FAIL, 
  USER_LOGOUT, 
  USER_SIGNUP_REQUEST, 
  USER_SIGNUP_SUCCESS, 
  USER_SIGNUP_FAIL,
  USER_UPDATE_REQUEST, 
  USER_UPDATE_SUCCESS, 
  USER_UPDATE_FAIL 
} from '../constants/userConstants';
import axios from 'axios';



export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST});

    const config = {
      headers: {
        "Content-type": "application/json"
      }
    };
    

    const { data } = await axios.post(
      "https://dailybiblejournalbackend.onrender.com/users/login",
      {
        email,
        password
      },
      config
    );

    
    dispatch({ type: USER_LOGIN_SUCCESS, payload: data});

    localStorage.setItem("userInfo", JSON.stringify(data));

  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  };
};








export const logout = () => async (dispatch) => {
  localStorage.removeItem("userInfo");
  dispatch({type: USER_LOGOUT});
};






export const signup = (name, email, password) => async (dispatch) => {
    try {

      dispatch({ type: USER_SIGNUP_REQUEST });

      const config = {
        headers: {
          "Content-type": "application/json"
        },
      };

      const { data } = await axios.post(
        "https://dailybiblejournalbackend.onrender.com/users/",
        {name, email, password},
        config
      )

      dispatch({ type: USER_SIGNUP_SUCCESS, payload: data });

      dispatch({ type: USER_LOGIN_SUCCESS, payload: data});

      localStorage.setItem("userInfo", JSON.stringify(data));


    } catch (error) {
      dispatch({
        type: USER_SIGNUP_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      }); 
    };
};

export const updateProfile = (user) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_UPDATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post("https://dailybiblejournalbackend.onrender.com/users/account", user, config);

    dispatch({ type: USER_UPDATE_SUCCESS, payload: data });

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
