export const ENTRY_LIST_REQUEST = "ENTRY_LIST_REQUEST";
export const ENTRY_LIST_SUCCESS = "ENTRY_LIST_SUCCESS";
export const ENTRY_LIST_FAIL = "ENTRY_LIST_FAIL";

export const ENTRY_MADE_REQUEST = "ENTRY_MADE_REQUEST";
export const ENTRY_MADE_SUCCESS = "ENTRY_MADE_SUCCESS";
export const ENTRY_MADE_FAIL = "ENTRY_MADE_FAIL";

export const ENTRY_UPDATE_REQUEST = "ENTRY_UPDATE_REQUEST";
export const ENTRY_UPDATE_SUCCESS = "ENTRY_UPDATE_SUCCESS";
export const ENTRY_UPDATE_FAIL = "ENTRY_UPDATE_FAIL";

export const ENTRY_DELETE_REQUEST = "ENTRY_DELETE_REQUEST";
export const ENTRY_DELETE_SUCCESS = "ENTRY_DELETE_SUCCESS";
export const ENTRY_DELETE_FAIL = "ENTRY_DELETE_FAIL";